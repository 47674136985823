
import { defineComponent } from 'vue';
import {
	IonGrid,
	IonRow,
	IonCol,
	IonButton,
	IonText,
	IonSelect,
	IonSelectOption,
	alertController,
} from '@ionic/vue';
import { addIcons } from 'ionicons';
import { checkmark, closeCircle } from 'ionicons/icons';
import { Group, Game, Player, Match } from '@/interfaces';
import GameItem from '@/components/GameItem.vue';

export default defineComponent({
	name: 'AddMatch',
	data() {
		return {
			groupId: this.$route.params.id,
			matchOptions: [] as any,
			matchId: 0,
			playerA: 0,
			playerB: 0,
			matchSetupValid: false,
			msg: 'Välj en match',
			btnDisabled: true,
			games: [
				{
					name: 'Set 1',
					pointA: undefined,
					pointB: undefined,
					valid: false,
				},
				{
					name: 'Set 2',
					pointA: undefined,
					pointB: undefined,
					valid: false,
				},
				{
					name: 'Set 3',
					pointA: undefined,
					pointB: undefined,
					valid: false,
				},
				{
					name: 'Set 4',
					pointA: undefined,
					pointB: undefined,
					valid: false,
				},
				{
					name: 'Set 5',
					pointA: undefined,
					pointB: undefined,
					valid: false,
				},
			],
		};
	},
	created() {
		addIcons({
			'close-circle': closeCircle,
			checkmark,
		});
	},
	methods: {
		matchSelected() {
			const match = this.group.matches.find(
				({ id }) => id === this.matchId
			) as Match;
			if (!match) return;
			const { players } = match;
			this.playerA = players[0];
			this.playerB = players[1];
			this.msg = 'Ange setsiffror';
			this.matchSetupValid = true;
		},
		reset() {
			this.playerA = 0;
			this.playerB = 0;
			this.matchId = 0;
		},
		async onSumbit() {
			const games = JSON.parse(JSON.stringify(this.games)) as Game[];
			const finishedGames = games.filter(({ valid }) => valid);
			const aGames = finishedGames.filter(
				({ pointA, pointB }) => pointA > pointB
			).length;
			const bGames = finishedGames.filter(
				({ pointA, pointB }) => pointA < pointB
			).length;
			const winnerId = aGames > bGames ? this.playerA : this.playerB;
			const winner = this.group.players.find(
				({ id }) => id === winnerId
			) as Player;
			const wins = Math.max(aGames, bGames);
			const loss = Math.min(aGames, bGames);

			const alert = await alertController.create({
				header: 'Bekräfta!',
				message: `Vann ${winner.name} matchen, med ${wins} - ${loss} i set?`,
				buttons: [
					{
						text: 'Avbryt',
						role: 'cancel',
					},
					{
						text: 'Ja',
						handler: () => {
							const matchIndex = this.group.matches.findIndex(
								({ id }) => id === this.matchId
							);
							this.group.matches[matchIndex].games = games
								.filter(({ valid }) => valid)
								.map(({ pointA, pointB }) => [pointA, pointB]);
							this.$store.dispatch('updateGroup', this.group);
							this.reset();
							this.$router.back();
						},
					},
				],
			});
			return alert.present();
		},
	},
	computed: {
		group(): Group {
			return this.$store.getters.group(this.groupId);
		},
	},
	ionViewDidEnter() {
		for (const { players, id } of this.group.matches.filter(
			(x) => !x.games.length
		)) {
			const playerA = this.group.players.find(
				({ id }) => id === players[0]
			)?.name;
			const playerB = this.group.players.find(
				({ id }) => id === players[1]
			)?.name;
			const matchId = {
				value: id,
				label: `${playerA} - ${playerB}`,
			};
			this.matchOptions.push(matchId);
		}
	},
	watch: {
		games: {
			deep: true,
			handler() {
				const games = JSON.parse(JSON.stringify(this.games)) as Game[];
				const finishedGames = games.filter(({ valid }) => valid);
				const aGames = finishedGames.filter(
					({ pointA, pointB }) => pointA > pointB
				).length;
				const bGames = finishedGames.filter(
					({ pointA, pointB }) => pointA < pointB
				).length;

				if (this.group.matchType === Math.max(aGames, bGames)) {
					this.btnDisabled = false;
				} else {
					this.btnDisabled = true;
				}
			},
		},
	},
	components: {
		IonGrid,
		IonRow,
		IonCol,
		IonButton,
		IonText,
		IonSelect,
		IonSelectOption,
		GameItem,
	},
});
