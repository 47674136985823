
import { defineComponent } from 'vue';
import {
	IonList,
	IonLabel,
	IonItem,
	IonGrid,
	IonRow,
	IonCol,
	IonButton,
	IonIcon,
	IonInput,
} from '@ionic/vue';
import { addIcons } from 'ionicons';
import { checkmark, closeCircle } from 'ionicons/icons';
import { Game } from '@/interfaces';

export default defineComponent({
	name: 'GameItem',
	props: ['gameData'],
	data() {
		return {
			game: this.gameData,
		};
	},
	created() {
		addIcons({
			'close-circle': closeCircle,
			checkmark,
		});
	},
	methods: {
		checkGame(game: Game) {
			const min = Math.min(game.pointA, game.pointB);
			const max = Math.max(game.pointA, game.pointB);
			if (max === 11 && max - min >= 2) {
				game.valid = true;
			} else if (max > 11 && max - min === 2) {
				game.valid = true;
			} else {
				game.valid = false;
			}
		},
	},
	components: {
		IonList,
		IonGrid,
		IonItem,
		IonLabel,
		IonRow,
		IonCol,
		IonButton,
		IonIcon,
		IonInput,
	},
});
